export default {
  // AksesToko Link
  AksesToko: 'https://qa.aksestoko.id/home',

  // Token
  storageTokenName: 'accesstoken',

  // Sales
  sales: 'user/listtransaction',

  // Customer
  customer: 'user/customer',

  // Cashier
  cashier: 'user/cashier',

  // Transaction
  kodeTransaction: 'user/transaction/generatecode',
  payTransaction: 'user/transaction/pay',
  detailTransaction: 'user/transaction/',

  // Antrian
  antrianData: 'user/transaction/load/',
  addAntrianData: 'user/transaction/queue',
  listAntrian: 'user/transaction/queue/list',
  loadAntrian: 'user/transaction/load/',

  // List
  category: 'category',
  subcategory: 'subcategory',
  brand: 'brand',
  faq: 'user/faq',
  type: 'type',
  uom: 'uom',
  productSig: 'product',
  productToko: 'product/listProduct',
  productTokoPage: 'product/productToko',

  // Store & Update Produk Toko
  storeProduct: 'product/store',
  updateProduct: 'product/update',

  // Dashboard
  graphData: 'dashboard',
  getGoalOverviewData: 'dashboard/sales',
  getTransactionsData: 'dashboard/product',
  graphTokoBangunan: 'admin/dashboard/chartSalesToko',
  graphProductSales: 'admin/dashboard/chartSalesProduct',
  graphBestSeller: 'admin/dashboard/chartSalesProductQty',
  graphProductGrossing: 'admin/dashboard/chartGrossProduct',
  graphFavouriteProduct: 'admin/dashboard/chartBrandProduct',

  // Debt
  payDebt: 'user/debt',

  // History
  transactHistory: 'history',
  logTransaction: 'user/historypayment',

  // Profile
  getProfile: 'toko/show',
  updateProfile: 'toko/update',
  deleteCashier: 'user/cashier/',

  // ===============================================================
  // Admin View :
  // ===============================================================
  // Master Data :
  getCustomerList: 'admin/customer',
  getTokoList: 'admin/tokobangunan',
  getCategoryAdmin: 'admin/category/listCategory',
  getAdminCategory: 'admin/category/listCategory',
  addAdminCategory: 'admin/category/store',
  updateAdminCategory: 'admin/category/update/',
  deleteAdminCategory: 'admin/category/delete/',
  getAdminPriceList: 'admin/product/listProductPrice',
  getAdminSalesList: 'admin/transaction',
  getAdminTokoList: 'admin/tokobangunan',
  getAdminBrand: 'admin/brand/listBrand',
  addAdminBrand: 'admin/brand/store',
  updateAdminBrand: 'admin/brand/update/',
  deleteAdminBrand: 'admin/brand/delete/',
  getAdminType: 'admin/type/listType',
  addAdminType: 'admin/type/store',
  updateAdminType: 'admin/type/update/',
  deleteAdminType: 'admin/type/delete/',
  getAdminUom: 'admin/uom/listUom',
  addAdminUom: 'admin/uom/store',
  updateAdminUom: 'admin/uom/update/',
  deleteAdminUom: 'admin/uom/delete/',
  getAdminSubCategory: 'admin/subcategory/listSubCategory',
  addAdminSubCategory: 'admin/subcategory/store',
  updateAdminSubCategory: 'admin/subcategory/update/',
  deleteAdminSubCategory: 'admin/subcategory/delete/',
  getAdminProductSIGList: 'admin/product/listProductSIG',
  getAdminProductNonSIGPriceList: 'admin/product/listProductNonSIG',
  getSubCategoryListAdmin: 'admin/subcategory/listSubCategory',
  getBrandListAdmin: 'admin/brand/listBrand',
  getTypeListAdmin: 'admin/type/listType',
  getUomListAdmin: 'admin/uom/listUom',
  saveProductAdmin: 'admin/product/store',
  updateProductAdmin: 'admin/product/update',
  deleteProductAdmin: 'admin/product/delete/',
  importProductAdmin: 'admin/product/import',

  getFaqAdmin: 'admin/faq/detail/',
  getAdminFaq: 'admin/faq',
  addAdminFaq: 'admin/faq/store',
  updateAdminFaq: 'admin/faq/update',
  deleteAdminFaq: 'admin/faq/delete/',

  // Apps version

  getAppsVersionList: 'admin/app-version',
  getAppsVersionDetail: 'admin/app-version/show',
  getAppsVersionCreate: 'admin/app-version/store',
  getAppsVersionUpdate: 'admin/app-version/update',
  getAppsVersionDelete: 'admin/app-version/delete/',

  // Pengumuman

  getNotifikasi: 'admin/notification',
  getNotifikasiDetail: 'admin/notification/show',
  getNotifikasiCreate: 'admin/notification/store',
  getNotifikasiUpdate: 'admin/notification/update',
  getNotifikasiDelete: 'admin/notification/delete/',

  // Metabase dashboard
  getMetabaseDashboard: 'metabase/dashboard',
}
